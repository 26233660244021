<script lang="ts" setup>
import { cn, ID } from '@gem/common';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { getLabelWithLang } from '@gem/i18n';
import { GIcon, GSvg } from '@gem/icons';
import { GBaseIcon } from '@gem/uikit';
import type { GDropDownDisplayItem, GDropdownMenuProps } from '../types';
import GDropdownMenu from './GDropdownMenu.vue';
import GPopover from './GPopover.vue';

export type GDropdownProps = {
  id: string;
  value?: string[];
  disabled: boolean;
  blankText?: string;
  disableDefault?: boolean;
  emptyMessage?: string;
  overlayContainer?: string;
  showSelectedIcon?: boolean;
  showSelectedSvg?: boolean;
  selectedSvgWidth?: number;
  disableSelectedText?: boolean;
  hiddenHtmlTag?: boolean;
} & GDropdownMenuProps;

const props = withDefaults(defineProps<GDropdownProps>(), {
  options: () => [],
  size: 'medium',
  floating: true,
  fixWidth: false,
  disabled: false,
  searchable: false,
  searchTerm: 'name',
  isShowActive: true,
  isSelectNumber: false,
  checkIconEnable: true,
  placement: 'bottom-end',
  showSelectedIcon: false,
  showSelectedSvg: false,
  disableSelectedText: false,
});

const emit = defineEmits<{
  (e: 'select', value: string[]): void;
}>();

const button = ref<HTMLElement>();

const dropdownID = `btn-select-${ID()}`;

const isSemiMediumSize = computed(() => {
  return props.size == 'semi-medium';
});

const isMediumSize = computed(() => {
  return props.size == 'medium';
});

const isLargeSize = computed(() => {
  return props.size == 'large';
});

const getDropdownButtonClass = (isOpenDropdown: boolean) => {
  return cn(
    'bg-dark-400 relative flex w-full items-center rounded-xl border border-transparent px-8 outline-none transition duration-200 disabled:cursor-not-allowed',
    {
      '!border-primary-300': isOpenDropdown,
    },
    props.lightMode
      ? 'border-primary-300 text-text-light-500 hover:bg-light-450 active:border-light-300'
      : 'text-light-450 hover:bg-dark-200 placeholder:text-text-dark-100 disabled:border-dark-200 disabled:text-text-dark-100 ',
    {
      'text-12 h-32': isSemiMediumSize.value,
      'text-12 h-36': isMediumSize.value,
      'text-14 h-40': isLargeSize.value,
    },
  );
};

const activeOption = computed(() => {
  const selected = props.items?.find((v) => v.value === props.value?.[0]);
  if (selected) return selected;

  if (props.disableDefault) return undefined;

  return props?.items?.[0];
});

const mapWithLangItems = computed(() => {
  return props.items?.map((item) => {
    return {
      ...item,
      title: getLabelWithLang(item.title),
    };
  });
});

const selectedOptionsValue = computed(() => {
  if (!props.value || !props.items) return activeOption.value ? [activeOption.value?.value] : [];
  return props.items.filter((item) => props.value?.includes(item.value))?.map((item) => item.value);
});
const displayItem: ComputedRef<GDropDownDisplayItem> = computed(() => {
  if (!activeOption.value) {
    return { text: props?.blankText ?? '' };
  }

  const text = getLabelWithLang(activeOption.value.title);

  return {
    text: text,
    icon: activeOption.value.icon,
    svgIcon: activeOption.value.svgIconSelected || activeOption.value.svgIcon,
  };
});
</script>

<template>
  <GPopover
    :overlay="false"
    cls="bg-dark-400 p-0"
    :has-arrow="false"
    :closeable="true"
    :placement-offset="4"
    placement="bottom-end"
    button-class="!w-full "
    wrap-content-class="!w-full"
    :overlay-container="overlayContainer"
    :ignore-outside-class="[dropdownID]">
    <template #default="{ open: isOpenDropdown }">
      <button
        :id="id"
        ref="button"
        data-test="editor-control-g-select-button"
        class="btn-select border-transparent"
        :disabled="disabled"
        :class="cn(getDropdownButtonClass(isOpenDropdown), dropdownID)">
        <div class="flex w-full items-center justify-between">
          <div class="flex min-w-0 items-center gap-8" :class="disableSelectedText ? 'w-auto' : 'w-full'">
            <GSvg
              v-if="displayItem.svgIcon && showSelectedSvg"
              :name="displayItem.svgIcon"
              :width="selectedSvgWidth"
              :height="20"
              class="flex-shrink-0" />
            <GIcon v-if="displayItem.icon && showSelectedIcon" :name="displayItem.icon" class="flex-shrink-0" />
            <span
              v-if="!disableSelectedText"
              class="text-12 min-w-0 truncate"
              :class="{ 'text-text-dark-100': !activeOption }">
              <span v-if="hiddenHtmlTag" v-html="displayItem.text"></span>
              <span v-else>{{ displayItem.text }}</span>
            </span>
          </div>

          <GBaseIcon class="ml-2 flex-shrink-0" name="chevron-down" width="20" height="20" viewBox="0 0 16 16" />
        </div>
      </button>
    </template>
    <template #content="{ close: closeDropdown }">
      <GDropdownMenu
        :items="mapWithLangItems"
        :show-checked-icon="true"
        :is-multi-select="false"
        :selected="selectedOptionsValue"
        :fix-width="fixWidth"
        :more-action="moreAction"
        :empty-message="emptyMessage"
        @on-select="
          (value) => {
            emit('select', value);
            closeDropdown();
          }
        " />
      <slot name="footer" />
    </template>
  </GPopover>
</template>
