<script lang="ts" setup>
import { cn } from '@gem/common';
import { useI18n } from '@gem/i18n';
import { GButton, GInput } from '@gem/uikit-v2';
import { computed, onMounted, ref, watch } from 'vue';
import { DROPDOWN_MENU_STYLE_BY_SIZE } from '../const/dropdownMenu';
import type { GDropdownMenuItemProps, GDropdownMenuProps } from '../types/dropdownMenu';
import GDropdownMenuItem from './GDropdownMenuItem.vue';
const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<(e: 'onSelect', value: GDropdownMenuItemProps['value'][]) => void>();
const props = withDefaults(defineProps<GDropdownMenuProps>(), {
  lightMode: false,
  size: 'medium',
  enableSearch: false,
  enableAction: false,
  isMultiSelect: false,
  showCheckedIcon: false,
  fixWidth: false,
});
const scrollBody = ref<any>();

const stylesBySize = computed((): string =>
  props.size ? DROPDOWN_MENU_STYLE_BY_SIZE[props.size] : DROPDOWN_MENU_STYLE_BY_SIZE.medium,
);

const selectedItems = ref(props.selected || []);

const searchQuery = ref('');

const filteredItems = computed(() => {
  if (!searchQuery.value) return props.items;

  return props.items?.filter((item) => item.title.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

const handleSearch = (value: string) => {
  searchQuery.value = value;
};

const removeItemFormSelectedItem = (item: string) => {
  if (props.minSelected && props.minSelected === selectedItems.value.length) return;
  const itemIndex = selectedItems.value.findIndex((i: string) => item === i);
  if (itemIndex > -1) {
    selectedItems.value.splice(itemIndex, 1);
  }
};

const addSelectedItem = (item: string) => {
  if (props.maxSelected && props.maxSelected === selectedItems.value.length) return;
  selectedItems.value.push(item);
};

const handleClick = (_event: Event, value: GDropdownMenuItemProps['value']) => {
  if (props.isMultiSelect) {
    !selectedItems.value.includes(value) ? addSelectedItem(value) : removeItemFormSelectedItem(value);
  } else {
    selectedItems.value = [value];
  }
  emit('onSelect', selectedItems.value);
};

const scrollToActiveItem = () => {
  if (props.items?.length < 6 || !selectedItems.value?.[0]) return;
  const findIndex = props.items?.findIndex((item) => item.value === selectedItems.value?.[0]);
  if (!findIndex || !scrollBody.value) return;
  scrollBody.value.$el.scrollTop = (findIndex - 3) * 36;
};

const isActiveItem = (itemValue: string) => {
  return selectedItems.value.includes(itemValue);
};

watch(
  () => props.selected,
  (value) => {
    selectedItems.value = value || [];
  },
);

onMounted(() => {
  scrollToActiveItem();
});
</script>

<template>
  <div
    class="rounded-12 flex w-full flex-col"
    :class="[cn([stylesBySize, lightMode ? 'bg-light-100' : 'bg-dark-400']), fixWidth ? 'w-[248px]' : 'min-w-[180px]']">
    <template v-if="filteredItems?.length">
      <GInput
        v-if="enableSearch"
        size="small"
        input-style="normal"
        :searchable="true"
        :clear-button="true"
        :placeholder="t('Search...')"
        @on-change="handleSearch">
        <template #icon>
          <GBaseIcon class="text-white" name="search" width="16" height="16" />
        </template>
      </GInput>
      <div :class="items?.length > 6 ? 'h-[187px]' : 'h-auto'">
        <perfect-scrollbar
          ref="scrollBody"
          :class="items?.length > 6 ? 'pr-8' : ''"
          :options="{ suppressScrollX: true }">
          <GDropdownMenuItem
            v-for="item in filteredItems"
            :key="item.value"
            v-bind="item"
            :active="isActiveItem(item.value)"
            :size="size"
            :light-mode="lightMode"
            :show-checked-icon="isMultiSelect || showCheckedIcon"
            @click="handleClick" />
        </perfect-scrollbar>
      </div>
    </template>
    <div v-else>
      <GDropdownMenuItem :title="emptyMessage" :no-selected="true" />
    </div>
    <div v-if="moreAction" class="border-light-500/[0.15] flex justify-center border-t pt-8 pb-4">
      <GButton type="link" size="small" button-width="full" @click="moreAction.action">{{ moreAction.label }}</GButton>
    </div>
  </div>
</template>
