<script lang="ts" setup>
import { cn, lightenDarkenColor } from '@gem/common';
import { useI18n } from '@gem/i18n';
import { GIcon } from '@gem/icons';
import { GButton, GTooltip } from '@gem/uikit-v2';
import { computed, ref, watch } from 'vue';
import { INPUT_COMBO_ACTION_STYLE_BY_TYPE, INPUT_COMBO_STYLE_BY_TYPE } from '../const/inputCombo';
import type { GInputComboProps } from '../types';

const { t } = useI18n({ useScope: 'global' });

const defaultPlaceholder = `${t('Add')}...`;

const props = withDefaults(defineProps<GInputComboProps>(), {
  disable: false,
});
const emit = defineEmits<{
  (e: 'clear'): void;
}>();

const stylesByState = computed((): string => {
  const style = INPUT_COMBO_STYLE_BY_TYPE;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.active) return cn(style.default[mode], style.hover[mode], style.active[mode]);
  if (props.disable) return style.disabled[mode];
  if (props.errorMessage) return style.error[mode];
  return cn(style.default[mode], style.hover[mode]);
});

const actionStylesByTypeAndState = computed((): string => {
  const style = parseValue.value ? INPUT_COMBO_ACTION_STYLE_BY_TYPE.hasValue : INPUT_COMBO_ACTION_STYLE_BY_TYPE.noValue;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.active) return cn(style.default[mode], style.hover[mode], style.active[mode]);
  if (props.disable) return style.disabled[mode];
  if (props.errorMessage) return style.error[mode];
  return cn(style.default[mode], style.hover[mode]);
});

const isSolidColor = computed(() => props.prefixColor && !props.prefixColor.includes('linear-gradient'));

const parseValue = ref(removeEnter(props.value));
function removeEnter(value?: string) {
  return value?.toString()?.replace(/&nbsp;/g, ' ') || '';
}

const handleClear = () => {
  parseValue.value = '';
  emit('clear');
};

watch(
  () => props.value,
  () => {
    parseValue.value = props.value || '';
  },
);
</script>

<template>
  <div class="group relative w-full" data-test="g-input-combo">
    <div
      class="text-12 relative flex h-36 w-full cursor-pointer items-center rounded-xl px-4 pl-[40px] leading-normal"
      :class="
        cn([
          stylesByState,
          clearButton && parseValue && active ? 'pr-[40px]' : '',
          clearButton && parseValue ? 'group-hover:pr-[40px]' : '',
        ])
      ">
      <div
        class="absolute left-4 top-1/2 h-[28px] w-[28px] -translate-y-1/2 overflow-hidden rounded-[6px]"
        :class="cn([prefixColor || prefixImage ? 'gemx-transparent-bg' : '', isSolidColor ? 'border' : ''])"
        :style="{
          borderColor: isSolidColor ? lightenDarkenColor(prefixColor || '') : 'none',
        }">
        <div
          v-if="prefixColor || prefixImage"
          class="h-full w-full bg-contain bg-center bg-no-repeat"
          :style="{
            backgroundColor: prefixColor?.includes('linear-gradient') ? 'transparent' : prefixColor,
            backgroundImage: prefixImage
              ? `url('${prefixImage}')`
              : prefixColor?.includes('linear-gradient')
              ? prefixColor
              : 'none',
          }" />
        <div
          v-else
          class="flex h-[28px] w-[28px] items-center justify-center rounded-[6px]"
          :class="cn([actionStylesByTypeAndState])">
          <div v-if="prefixSvg" class="h-[20px] w-[20px]" v-html="prefixSvg"></div>
          <GIcon v-else :name="prefixIcon || 'polaris-plus'" :size="16" />
        </div>
      </div>
      <div v-if="parseValue" class="mt-[1px] w-full truncate">{{ parseValue }}</div>
      <div v-else class="text-text-dark-100 mt-[1px] w-full truncate">{{ placeholder ?? defaultPlaceholder }}</div>
      <template v-if="clearButton && parseValue">
        <div v-if="parseValue" class="absolute right-8 hidden group-hover:block" :class="{ '!block': active }">
          <GTooltip :disabled="!disableClearMessage" placement="top" :is-teleport="true" :margin-top="4">
            <GButton
              :disable="!!disableClearMessage"
              type="ghost"
              size="small"
              only-icon="polaris-x"
              @click.stop="handleClear" />
            <template #content>
              <span v-html="disableClearMessage" />
            </template>
          </GTooltip>
        </div>
      </template>
    </div>
    <div v-if="errorMessage" class="text-12 font-regular mt-8 flex flex-row gap-8 text-red-200">
      <GIcon name="polaris-alert-circle" :size="16" /><span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style scoped>
.gemx-transparent-bg {
  background-image: url('/images/bg-transparent.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
