<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { GInputProps } from '../types/input';
import { GButton } from '@gem/uikit-v2';
import { BUTTON_STYLE_BY_SIZE, INPUT_STYLE_BY_TYPE } from '../const/input';
import { cn, parseUnit } from '@gem/common';
import { GIcon } from '@gem/icons';
import { useDebounceFn } from '@vueuse/core';

const props = withDefaults(defineProps<GInputProps>(), {
  inputStyle: 'normal',
  type: 'text',
  size: 'medium',
  disable: false,
  errorMessage: '',
  enableDebounceOnChange: false,
});

const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'onChange', value: string): void;
  (e: 'enter', value: string): void;
  (e: 'blur', event?: any): void;
  (e: 'clickOutSide', value: string): void;
  (e: 'focus', event: any): void;
  (e: 'clear'): void;
}>();

const inputRef = ref<HTMLInputElement>();
const isFocus = ref<boolean>(false);
const previousValue = ref();
const parseValue = ref(removeEnter(props.value));
const isClickClear = ref<boolean>(false);
const isShowClearButton = ref(false);

const isChangeValue = computed(() => {
  return previousValue.value !== parseValue.value;
});

function focus() {
  if (inputRef.value) {
    inputRef.value.focus();
  }
}

function updateValue(value: string) {
  parseValue.value = value;
}

function getValue() {
  if (inputRef.value) {
    return inputRef.value.value;
  }
}

defineExpose({
  focus,
  updateValue,
  getValue,
});

const stylesByTypeAndState = computed((): string => {
  const style = props.inputStyle
    ? INPUT_STYLE_BY_TYPE[props.inputStyle] || INPUT_STYLE_BY_TYPE.normal
    : INPUT_STYLE_BY_TYPE.normal;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.readonly) return cn(style.default[mode], style.hover[mode], props.active ? style.active[mode] : '');
  if (props.disable) return style.disabled[mode];
  if (props.errorMessage) return style.error[mode];
  return cn(style.default[mode], style.hover[mode], style.focus[mode]);
});

const styleBorder = computed(() => {
  const classNames = ['rounded-xl'];
  if (props.noBorder) {
    if (props.noBorder === 'left') classNames.push('rounded-l-none border-l-0');
    if (props.noBorder === 'right') classNames.push('rounded-r-none border-r-0');
    if (props.noBorder === 'top') classNames.push('rounded-t-none border-t-0');
    if (props.noBorder === 'bottom') classNames.push('rounded-b-none border-b-0');
    if (props.noBorder === 'all') classNames.push('rounded-none !border-0');
  }
  return cn(classNames);
});

const errorMessageColor = computed((): string => {
  if (props.errorMessage) {
    return props.lightMode ? 'text-red-400' : 'text-red-300';
  }
  return '';
});

const stylesBySize = computed((): string =>
  props.size ? BUTTON_STYLE_BY_SIZE[props.size] : BUTTON_STYLE_BY_SIZE.large,
);

const emitValue = (type: 'change' | 'onChange' | 'clickOutSide' | 'enter', value?: string) => {
  if (!isChangeValue.value && type !== 'onChange') return;
  const valueEmit = removeEnter(value?.trim() || '');
  if (type === 'onChange') {
    emit('onChange', valueEmit);
  } else {
    switch (type) {
      case 'change':
        emit('change', valueEmit);
        isShowClearButton.value = false;
        break;
      case 'enter':
        emit('enter', valueEmit);
        break;
      case 'clickOutSide':
        emit('clickOutSide', valueEmit);
        break;
    }
    parseValue.value = props.value;
  }
  isClickClear.value = false;
};

const handleClear = (e: any) => {
  e.preventDefault();
  isClickClear.value = true;
  parseValue.value = '';
  !props.readonly && inputRef.value?.focus();
  emit('change', '');
  emit('clear');
};

const getStringLength = (str: string) => {
  return new TextEncoder().encode(str).length;
};

const maxLengthLabel = computed(() => {
  if (!props.maxLength) return '';
  const characterCount = parseValue.value?.length ? getStringLength(parseValue.value) : 0;
  return `${characterCount}/${props.maxLength}`;
});

const inputPaddingBySize = computed(() => {
  switch (props.size) {
    case 'large':
      return 'pl-34';
    case 'medium':
      return 'pl-32';
    case 'small':
      return 'pl-24';
    default:
      return 'pl-32';
  }
});

function removeEnter(value?: string) {
  return value?.toString()?.replace(/&nbsp;/g, ' ') || '';
}

const handleInputBlur = (e?: any) => {
  emit('blur', e);
  if (!isChangeValue.value) return;
  emitValue('clickOutSide', inputRef.value?.value);
  isFocus.value = false;
};

const handleOnChange = (e: any) => {
  parseValue.value = e.target.value;
  props.enableDebounceOnChange ? debouncedOnChange(e.target.value) : emitValue('onChange', e.target.value);
};

const debouncedOnChange = useDebounceFn((value?: string) => {
  emitValue('onChange', value);
}, 500);

const handleChange = (e: any) => {
  setTimeout(() => {
    if (isClickClear.value) return;
    emitValue('change', e.target.value);
  });
};

const handleFocus = (e: any) => {
  if (props.readonly) return;
  previousValue.value = parseValue.value;
  isFocus.value = true;
  const input = e.target;
  let start = 0;
  let end = input.value.search(/[^\d]/); // find the first non-digit
  if (props.isUnit && end > 0) {
    const [value] = parseUnit(input.value);
    if (value) {
      start = input.value.indexOf(value);
      end = start + value.toString().length;
    }
    input.setSelectionRange(start, end);
  } else {
    input.select();
  }
  emit('focus', e);
};

const handleFocusOut = () => {
  setTimeout(() => {
    isShowClearButton.value = false;
    isFocus.value = false;
  });
};

const handleDoubleClick = (e: any) => {
  if (!isFocus.value) {
    handleFocus(e);
  }
};

// useOutsideClick(inputRef, handleInputBlur, {
//   detectIframe: true,
//   containSelectors: [...(props.elmDisableBlurAction || ['gp-input-clear-btn'])],
// });

const keydownHandler = (e: KeyboardEvent) => {
  if (props.readonly) return;
  if (e.key === 'Enter') {
    emitValue('enter', inputRef.value?.value);
  }
  if (e.key === 'Delete' || e.key === 'Backspace') {
    e.stopPropagation();
  }
};

const onMouseOver = () => {
  isShowClearButton.value = true;
};
const onMouseLeave = () => {
  if (isFocus.value) return;
  isShowClearButton.value = false;
};

watch(
  () => props.active,
  () => {
    if (props.active && !props.readonly) focus();
  },
);

watch(
  () => props.value,
  () => {
    parseValue.value = props.value;
  },
);
</script>

<template>
  <div class="relative w-full" :style="{ maxWidth: maxWidth }" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <div class="relative flex w-full items-center">
      <div v-if="prefixIcon" class="absolute left-0 top-0 flex h-full w-32 items-center justify-center">
        <GIcon :name="prefixIcon" :size="20" />
      </div>
      <input
        ref="inputRef"
        v-model="parseValue"
        class="w-full text-ellipsis rounded-xl outline-none"
        data-test="editor-control-g-input-text"
        autocomplete="off"
        :placeholder="placeholder"
        :class="
          cn([
            stylesByTypeAndState,
            stylesBySize,
            styleBorder,
            maxLength ? (maxLength >= 100 ? 'pr-[58px]' : 'pr-[48px]') : '',
            prefixIcon ? inputPaddingBySize : '',
            clearButton && parseValue ? 'pr-[40px]' : '',
            readonly ? 'cursor-pointer select-none' : '',
            align ? `text-${align}` : '',
            type === 'number' ? 'appearance-none' : '',
            noBackground ? '!bg-transparent' : '',
          ])
        "
        :style="{
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
        }"
        :type="type"
        v-bind="$attrs"
        :disabled="disable"
        :readonly="readonly"
        :maxlength="maxLength"
        @keydown="keydownHandler"
        @blur="handleInputBlur"
        @focus="handleFocus"
        @focusout="handleFocusOut"
        @change="handleChange"
        @input="handleOnChange"
        @dblclick="handleDoubleClick" />
      <div v-if="maxLength && !clearButton" class="text-text-light-100 text-12 leading-20 absolute right-8">
        {{ maxLengthLabel }}
      </div>
      <template v-if="clearButton">
        <div v-if="isShowClearButton && parseValue" class="gp-input-clear-btn absolute right-8">
          <GButton
            type="ghost"
            size="small"
            only-icon="polaris-x"
            :light-mode="lightMode"
            @mousedown.prevent.stop="handleClear" />
        </div>
      </template>
    </div>
    <div
      v-if="errorMessage !== ''"
      class="text-12 font-regular mt-8 flex flex-row gap-8"
      :class="cn([errorMessageColor])">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
